'use client';

import * as React from 'react';
import classNames from 'classnames';
import LoadingContent from '../LoadingContent';
import Icon from '../Icon';
import styles from './styles.module.scss';

export interface IControlFilesProps extends React.HTMLProps<HTMLInputElement> {
  /** Title text to display */
  title: string;
  /** Color higlight for errors */
  hasError?: boolean;
  /** Pass input ref */
  inputRef?: React.RefCallback<HTMLInputElement>;
  /** Loading */
  loading?: boolean;
}

/**
 * Regular HTML input field with extended functionality and styling. Use this within form row.
 */
const ControlFiles: React.FunctionComponent<IControlFilesProps> = (props) => {
  const { title, inputRef, hasError, onFocus, onBlur, className, ...rest } = props;
  const [hasFocus, setHasFocus] = React.useState(false);
  const localInputRef = React.useRef<HTMLInputElement | null>(null);

  return (
    <div
      className={classNames(
        styles['control-files'],
        {
          [styles['has-error']]: hasError,
          [styles['focus']]: hasFocus,
          [styles['disabled']]: props.disabled,
        },
        className,
      )}
      style={props.style}
    >
      <div className={styles['control-files__label']}>
        <div>{title}</div>
        <Icon
          width={24}
          height={24}
          kind="document-upload"
        />
      </div>
      <div className={styles['control-files__holder']}>
        <input
          {...rest}
          type="file"
          className={styles['control-files__input']}
          onFocus={(e) => {
            setHasFocus(true);
            if (onFocus) {
              onFocus(e);
            }
          }}
          onBlur={(e) => {
            setHasFocus(false);
            if (onBlur) {
              onBlur(e);
            }
          }}
          ref={(e) => {
            if (inputRef) {
              inputRef(e);
            }
            if (e) {
              localInputRef.current = e;
              if (props.autoFocus) {
                window.requestAnimationFrame(() => {
                  e.focus();
                });
              }
            }
          }}
          aria-busy={props.loading}
        />
      </div>
      {props.loading && (
        <span className={styles['control-files__loader']}>
          <LoadingContent size="tiny" />
        </span>
      )}
    </div>
  );
};

ControlFiles.displayName = 'ControlFiles';

export default ControlFiles;
