'use client';

import * as React from 'react';
import Button, { IButtonProps } from '@/components/ui/Button';

export interface IButtonScrollProps extends IButtonProps {
  target: string;
}

const ButtonScroll: React.FunctionComponent<IButtonScrollProps> = (props) => {
  const { target, ...rest } = props;
  return (
    <Button
      {...rest}
      onClick={(e) => {
        e.preventDefault();
        const elem = document.getElementById(target);
        if (elem) {
          e.preventDefault();
          elem.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }}
    />
  );
};

ButtonScroll.displayName = 'ButtonScroll';

export default ButtonScroll;
