'use client';

import * as React from 'react';
import Icon from '@/components/ui/Icon';
import styles from './styles.module.scss';

const ListJobOffers: React.FunctionComponent<{
  data: {
    title: React.ReactNode;
    location: React.ReactNode;
    href: string;
  }[];
}> = (props) => {
  return (
    <ul className={styles['list-job-offers']}>
      {props.data.map((item, itemIndex) => {
        return (
          <li key={itemIndex}>
            <a
              href={item.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles['list-job-offers__title']}>
                <span>{item.title}</span>
                <Icon
                  width={24}
                  height={24}
                  kind="out-going-link"
                />
              </div>
              <div className={styles['list-job-offers__location']}>{item.location}</div>
            </a>
          </li>
        );
      })}
    </ul>
  );
};

ListJobOffers.displayName = 'ListJobOffers';

export default ListJobOffers;
