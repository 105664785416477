'use client';

import * as React from 'react';
import { ButtonChildren } from '../index';

export interface IButtonsSecondaryProps {
  children?: ButtonChildren | ButtonChildren[];
}

/**
 * Secondary button group to use within Buttons component. Rendered in discrete position.
 */
const ButtonsSecondary: React.FunctionComponent<IButtonsSecondaryProps> = (props) => {
  if (React.Children.count(props.children) === 0) {
    return null;
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
};

ButtonsSecondary.displayName = 'ButtonsSecondary';

export default ButtonsSecondary;
