'use client';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useOverlay } from '@/components/hooks/useOverlay';
import Overlay from '@/components/ui/Overlay';
import LayoutOverlay from '@/components/ui/LayoutOverlay';
import Block from '@/components/ui/Block';
import OverlayTitle from '@/components/ui/LayoutOverlay/OverlayTitle';
import ApplicationForm from '@/components/content/ApplicationForm';
import NonIdealState from '@/components/ui/NonIdealState';
import { afterOverlayClose } from '@/lib/helpers/overlays';

const OverlayJobApplication: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = React.useState(false);
  const { isOverlayOpen, zIndexOfOverlay, closeOverlay } = useOverlay('kandideeri');

  const onClose = () => {
    closeOverlay();
    afterOverlayClose(() => setSubmitted(false));
  };

  return (
    <Overlay
      isOpen={isOverlayOpen}
      onClose={onClose}
      layout="default"
      zIndex={zIndexOfOverlay}
      hideClose={true}
    >
      <LayoutOverlay size="default">
        <Block
          width="narrow"
          theme="lighter"
        >
          {submitted ? (
            <NonIdealState
              title={t('components.applicationForm.success')}
              imageUrl="/img/success.png"
              buttons={[
                {
                  title: t('components.applicationForm.close'),
                  theme: 'primary',
                  onClick: onClose,
                },
              ]}
            />
          ) : (
            <React.Fragment>
              <OverlayTitle
                title={t('components.applicationForm.title')}
                onClose={closeOverlay}
              />
              <ApplicationForm
                onCancel={closeOverlay}
                onSubmit={() => setSubmitted(true)}
              />
            </React.Fragment>
          )}
        </Block>
      </LayoutOverlay>
    </Overlay>
  );
};

OverlayJobApplication.displayName = 'OverlayJobApplication';

export default OverlayJobApplication;
