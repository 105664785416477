'use client';

import * as React from 'react';
import Button from '@/components/ui/Button';
import { useOverlay } from '@/components/hooks/useOverlay';
import OverlayJobApplication from '@/components/overlays/JobApplication';

export interface IButtonJobApplicationProps {
  title: string;
}

const ButtonJobApplication: React.FunctionComponent<IButtonJobApplicationProps> = (props) => {
  const { openOverlay } = useOverlay('kandideeri');
  return (
    <React.Fragment>
      <Button
        title={props.title}
        onClick={openOverlay}
        size="large"
        theme="primary"
      />
      <OverlayJobApplication />
    </React.Fragment>
  );
};

ButtonJobApplication.displayName = 'ButtonJobApplication';

export default ButtonJobApplication;
