import { IDebtInfo } from '@/components/content/AddDebtForm/interfaces';
import { IFormErrors } from '@/components/content/AddDebtForm';

export const initialState: IDebtInfo = {
  creditorName: '',
  creditorRegNo: '',
  creditorContactPerson: '',
  creditorEmail: '',
  creditorPhone: '',
  debtTime: '',
  debtSum: '',
  debtFiles: [],
};

export const accept = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'text/csv': ['.csv'],
};

export const fileAllowedFormats = Object.values(accept)
  .map((val) => val[0].replace('.', ''))
  .join(',');

export const formHasError = (errors: IFormErrors) => {
  return !!Object.values(errors).filter((val) => !!val).length;
};
