import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Buttons from '@/components/ui/Buttons';
import Button from '@/components/ui/Button';
import ControlFiles from '@/components/ui/ControlFiles';

interface UploadFileProps {
  accept: Record<string, string[]>;
  loading: boolean;
  files: File[];
  setFiles: (files: File[]) => void;
}

const isDuplicateFile = (existingFiles: File[], newFile: File) => {
  return existingFiles.some(
    (file) => file.name === newFile.name && file.size === newFile.size && file.lastModified === newFile.lastModified,
  );
};

const UploadFile: React.FC<UploadFileProps> = ({ accept, loading, files, setFiles }) => {
  const { t } = useTranslation();
  const handleFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files ? Array.from(event.target.files) : [];

    const acceptedFiles: File[] = [];

    newFiles.forEach((file) => {
      if (!isDuplicateFile(files, file)) {
        acceptedFiles.push(file);
      }
    });

    setFiles([...files, ...acceptedFiles]);
  };

  const handleFileRemove = (fileIndex: number) => {
    const updatedFiles = files.filter((_, index) => index !== fileIndex);
    setFiles(updatedFiles);
  };

  return (
    <React.Fragment>
      <ControlFiles
        title={t('components.applicationForm.addFiles')}
        accept={Object.values(accept).flat().join(',')}
        multiple
        disabled={loading}
        onChange={handleFilesAdded}
        className={'control-files'}
      />
      {files.length > 0 && (
        <Buttons>
          {files.map((file, fileIndex) => (
            <Button
              key={file.name + file.size + fileIndex}
              title={file.name}
              iconRight="delete"
              onClick={() => handleFileRemove(fileIndex)}
            />
          ))}
        </Buttons>
      )}
    </React.Fragment>
  );
};

export default UploadFile;
