'use client';

import * as React from 'react';
import Icon from '@/components/ui/Icon';
import styles from './styles.module.scss';

export interface IListQutesProps {
  items: React.ReactNode[];
}

const ListQutes: React.FunctionComponent<IListQutesProps> = (props) => {
  if (props.items.length === 0) {
    return null;
  }
  return (
    <ul className={styles['list-quotes']}>
      {props.items.map((item, itemIndex) => (
        <li key={itemIndex}>
          <Icon
            width={80}
            height={80}
            kind="quote-light"
          />
          <span>{item}</span>
          <Icon
            width={80}
            height={80}
            kind="quote-light"
          />
        </li>
      ))}
    </ul>
  );
};

ListQutes.displayName = 'ListQutes';

export default ListQutes;
